import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    center: {
      textAlign: "center",
      justifyContent: "center",
    },
    addBtn: {
      marginTop: "1.2rem",
      textAlign: "center",
      justifyContent: "center",
    },
    paper: {
      padding: 10,
    },
    textScale: {
      fontSize: 19,
      fontWeight: "bold",
    },
    picpayImg: {
      width: "2.3rem",
    },
    cieloImg: {
      width: "2.7rem",
    },
    imgTitleContainer: {
      flexDirection: "row",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "65%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);
export default useStyles;
