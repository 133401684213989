import React, { useState, useEffect } from "react";

import useStyles from "./styles";

import {
  LinearProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  FormGroup,
  FormControl,
  InputLabel,
  Button,
  Input,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";

import axios from "config/axios";

import image from "assets/img/bg.jpg";

import Header from "components/Header/Header";
import Snackbar from "components/Snackbar/Snackbar.js";

function WithdrawDelivery(props) {
  const [loading, setLoading] = useState(),
    [password, setPassword] = useState(""),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState(""),
    [cords, setCords] = useState(),
    [token] = useState(props?.match?.params?.token);
  const classes = useStyles();

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.watchPosition(
        function (position) {
          setCords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accu: position.coords.accuracy,
          });
        },
        (error) => {
          console.log("Erro ao ler GPS: " + error);
        },
        { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
      );
    } else {
      setAlert("Geolocalização não suportada pelo navegador");
    }
  }, []);

  const verify = async (e) => {
    e.preventDefault();
    setLoading((oldState) => !oldState);
    axios
      .post("/delivery/withdraw", {
        lat: cords?.lat,
        long: cords?.long,
        deliveryToken: token,
        password,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setAlertSuccess(`Porta ${data.door} aberta! Retire a sua encomenda`);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        const statusError = error?.response?.data?.status;
        switch (statusError) {
          case 1:
            setAlert("Campos faltando no pedido");
            break;
          case 406:
            setAlert("Você está longe da porta");
            break;
          case 2:
            setAlert("Encomenda não encontrada ou já retirada");
            break;
          case 3:
            setAlert("Porta não encontrada");
            break;
          case 4:
            setAlert("Armário não encontrado");
            break;
          case 5:
            setAlert("Senha incorreta");
            break;
          case 6:
            setAlert("Porta bloqueada");
            break;
          default:
            setAlert("Problema ao abrir");
            break;
        }
      });
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        <Snackbar
          place="tc"
          color={"danger"}
          icon={ErrorIcon}
          message={alert ? alert : ""}
          open={alert ? true : false}
          closeNotification={() => setAlert("")}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={InfoIcon}
          message={alertSuccess ? alertSuccess : ""}
          open={alertSuccess ? true : false}
          closeNotification={() => setAlertSuccess("")}
          close
        />
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Header />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  variant="h4"
                  style={{ marginBottom: "1rem" }}
                  align="center"
                >
                  Retirar encomenda
                </Typography>
                <form onSubmit={verify}>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">
                        Senha (enviada por email ou whatsapp)
                      </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) =>
                          setPassword(e.target.value.toLocaleLowerCase())
                        }
                      />
                    </FormControl>
                    <Button size="small" className={classes.btn} type="submit">
                      Abrir
                    </Button>
                  </FormGroup>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default WithdrawDelivery;
