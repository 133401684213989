import React, { useState, useEffect } from "react";

import {
  InputAdornment,
  Button,
  CardActions,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from "@material-ui/icons/Email";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";

import axios from "config/axios";

import Snackbar from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput";

import isEmail from "functions/isEmail";

import useStyles from "./styles";

function MakeDelivery() {
  const [loading, setLoading] = useState(false),
    [email, setEmail] = useState(""),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState(""),
    [confEmail, setConfEmail] = useState(""),
    [cellphone, setCellphone] = useState(""),
    [confCellphone, setConfCellphone] = useState(""),
    [cords, setCords] = useState(),
    [suggestions, setSuggestions] = useState(),
    [info, setInfo] = useState(""),
    [doorCod, setDoorCod] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.watchPosition(
        function (position) {
          setCords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accu: position.coords.accuracy,
          });
        },
        (error) => {
          console.log("Erro ao ler GPS: " + error);
        },
        { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
      );
    } else {
      setAlert("Geolocalização não suportada pelo navegador");
    }
  }, []);

  const makeDelivery = async () => {
    if (!doorCod) {
      return setAlert("Digite o código de uma porta");
    }
    if (!email || !confEmail) {
      return setAlert("Digite e confirme o email do cliente");
    }
    if (email !== confEmail) {
      return setAlert("Os emails não são iguais");
    }
    if (!isEmail(email)) {
      return setAlert("Email inválido");
    }
    if (!cellphone || !confCellphone) {
      return setAlert("Preencha os campos de celular");
    }
    if (cellphone !== confCellphone) {
      return setAlert("Os celulares são diferentes");
    }
    if (cellphone.length !== 11) {
      return setAlert("Celular inválido");
    }
    if (!info) {
      return setAlert("Insira alguma informação sobre essa entrega");
    }
    setLoading((oldState) => !oldState);
    setSuggestions(null);
    axios
      .post("/salesman/delivery", {
        doorCod,
        email,
        cellphone: "+55" + cellphone,
        lat: cords.lat,
        long: cords.long,
        info,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlertSuccess("Porta aberta, entregue a encomenda");
        setEmail("");
        setConfEmail("");
        setCellphone("");
        setConfCellphone("");
        setDoorCod("");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        const statusError = error?.response?.data?.status;
        switch (statusError) {
          //Caso 1001 do middleware de venvedor
          case 1001:
            setAlert("Você não é um vendedor");
            break;
          case 408:
            setAlert("Tempo de abertura esgotado");
            break;
          case 406:
            setAlert("Você está longe da porta");
            break;
          case 1:
            setAlert("Porta não encontrada");
            break;
          case 2:
            setAlert("Porta ocupada");
            setSuggestions(error.response.data.doors);
            break;
          default:
            setAlert("Problema ao abrir");
            break;
        }
      });
  };

  const procCod = async (e) => {
    let value = e.target.value;
    if (value.length > 8) {
      return;
    }
    setDoorCod(value.toLocaleLowerCase());
  };

  const procCellNumber = async (e) => {
    let value = e.target.value,
      name = e.target.name;
    value = value.replace(/\D/, "");
    if (value.length > 11) {
      return;
    }
    if (name === "cellphone") {
      setCellphone(value);
    } else {
      setConfCellphone(value);
    }
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color={"danger"}
        icon={ErrorIcon}
        message={alert ? alert : ""}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess ? alertSuccess : ""}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Entregar pedido</Typography>
              {suggestions && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ fontSize: "1.2rem", flexDirection: "column" }}
                    >
                      Portas disponíveis:
                    </Typography>
                  </Grid>
                  {suggestions.map((item, index) => (
                    <Grid item key={index}>
                      <Button
                        key={index}
                        style={{ fontSize: "1.2rem", textTransform: "none" }}
                        onClick={() => setDoorCod(item.cod)}
                      >
                        <b>{item.cod}</b>
                      </Button>
                    </Grid>
                  ))}
                  {suggestions.length === 0 && (
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="center"
                        style={{ flexDirection: "column" }}
                      >
                        <b>Não há portas disponíveis</b>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item>
                  <CustomInput
                    labelText="Código da porta"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "cod",
                      onChange: procCod,
                      value: doorCod,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <MeetingRoomIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email do cliente"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "cod",
                      onChange: (e) =>
                        setEmail(e.target.value.toLocaleLowerCase()),
                      value: email,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Confirme o email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "cod",
                      onChange: (e) =>
                        setConfEmail(e.target.value.toLocaleLowerCase()),
                      value: confEmail,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="DDD + Celular"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "tel",
                      name: "cellphone",
                      onChange: procCellNumber,
                      value: cellphone,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <LocalPhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Confirmar DDD + celular"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "tel",
                      name: "confNewCellphone",
                      onChange: procCellNumber,
                      value: confCellphone,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <LocalPhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    labelText="Informações da entrega"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "info",
                      onChange: (e) => setInfo(e.target.value),
                      value: info,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              className={classes.center}
              style={{ marginTop: "1rem" }}
            >
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={makeDelivery}
                  disabled={loading}
                  size="large"
                >
                  Entregar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default MakeDelivery;
