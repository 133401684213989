import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "90vw",
      marginTop: "12%",
      maxWidth: 400,
      marginBottom: "12%",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    btn: {
      padding: theme.spacing(1),
      textAlign: "center",
    },
    btnSecondary: {
      padding: theme.spacing(1),
      textAlign: "center",
    },
    btnSecondaryContainer: {
      textAlign: "center",
      marginTop: "1.5rem",
    },
    backgroundImage: {
      minHeight: "100vh",
      height: "auto",
      display: "inherit",
      position: "relative",
      margin: "0",
      padding: "0",
      border: "0",
      alignItems: "center",
    },
    input: {
      marginBottom: "5%",
      marginTop: "5%",
    },
    btnForgot: {
      marginTop: "4%",
    },
    logoMicro: {
      width: "90%",
      minWidth: "6rem",
      margin: "3%",
      marginTop: 10,
    },
    logoLockyt: {
      width: "85%",
      minWidth: "5rem",
      margin: "3%",
    },
    gridLogos: {
      margin: 10,
      width: "40%",
      maxWidth: "12rem",
      justifyContent: "center",
    },
    logos: {
      backgroundColor: "#1e1e1fe0",
    },
    btnGoogleContainer: {
      width: "10rem",
    },
    btnGoogle: {
      width: "10rem",
    },
  })
);

export default useStyles;
