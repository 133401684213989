import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import axios from "config/axios";
import queryString from "querystring";

import { AuthContext } from "context/AuthContext";

import ErrorIcon from "@material-ui/icons/Error";

import image from "assets/img/bg.jpg";

import fire from "config/firebase";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import InstallPWA from "components/InstallPWA/InstallPWA";

import useStyles from "./styles";

function Login() {
  let [loading, setLoading] = useState(false),
    [email, setEmail] = useState(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [registerName, setRegisterName] = useState(""),
    [registerEmail, setRegisterEmail] = useState(""),
    [registerCell, setRegisterCell] = useState(""),
    [hasCellphone, setHasCellphone] = useState(""),
    [registerUid, setRegisterUid] = useState(""),
    [register, setRegister] = useState(false),
    [selectedPerm, setSelectedPerm] = useState(""),
    [password, setPassword] = useState("");

  const authContext = useContext(AuthContext);

  let history = useHistory(),
    location = useLocation(),
    classes = useStyles();

  const loginGoogle = async () => {
    setLoading(true);
    fire
      .googleLogin()
      .then(async (val) => {
        axios
          .post("/login", {
            firebaseToken: await val.user.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            localStorage.setItem("@Lockyt:token", data.token);
            authContext.updateValue(data?.user?.additionalPerm);
            if (data?.user?.additionalPerm?.salesman) {
              return history.push("/salesman/dashboard");
            }
            return history.push("/admin/dashboard");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              case 404: {
                let { displayName, email, phoneNumber, uid } = val.user;
                /* Seta os dados obtidos pelo firebase */
                setRegisterName(displayName);
                setRegisterEmail(email);
                if (phoneNumber) {
                  setRegisterCell(phoneNumber);
                  setHasCellphone(true);
                } else {
                  setHasCellphone(false);
                }
                setRegisterUid(uid);
                setRegister(true);
                break;
              }
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar com o google, tente novamente",
                  color: "danger",
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/unauthorized-domain":
            setAlert({
              alert: true,
              msg: "Domínio não autorizado",
              color: "danger",
            });
            break;
          case "auth/cancelled-popup-request":
            return;
          case "auth/popup-closed-by-user":
            return;
          case "auth/network-request-failed":
            setAlert({
              alert: true,
              msg: "Se conecte a internet para fazer login",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar com o google, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  const firebaseLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    fire
      .emailLogin(email, password)
      .then(async (val) => {
        if (!val.user.emailVerified) {
          setLoading(false);
          return setAlert({
            alert: true,
            msg: "Email não verificado",
            color: "danger",
          });
        }
        axios
          .post("/login", {
            firebaseToken: await val.user.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            localStorage.setItem("@Lockyt:token", data.token);
            authContext.updateValue(data?.user?.additionalPerm);
            if (data?.user?.additionalPerm?.salesman) {
              return history.push("/salesman/dashboard");
            }
            return history.push("/admin/dashboard");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              case 404: {
                let { displayName, email, phoneNumber, uid } = val.user;
                /* Seta os dados obtidos pelo firebase */
                setRegisterName(displayName);
                setRegisterEmail(email);
                if (phoneNumber) {
                  setRegisterCell(phoneNumber);
                  setHasCellphone(true);
                } else {
                  setHasCellphone(false);
                }
                setRegisterUid(uid);
                setRegister(true);
                break;
              }
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar, tente novamente",
                  color: "danger",
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/wrong-password":
            setAlert({
              alert: true,
              msg: "Senha incorreta",
              color: "danger",
            });
            break;
          case "auth/user-not-found":
            setAlert({
              alert: true,
              msg: "Usuário não encontrado! CADASTRE-SE no Lockyt",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  const activateAccount = async (token) => {
    setLoading(true);
    axios
      .post("/validateEmail", { token })
      .then(() => {
        setLoading(false);
        setAlertSuccess({ alert: true, msg: "Conta ativada com sucesso" });
      })
      .catch((error) => {
        setLoading(false);
        switch (error?.response?.status) {
          case 404:
            setAlert({ alert: true, msg: "Ativação não encontrada" });
            break;
          case 401:
            setAlert({ alert: true, msg: "Conta já ativada" });
            break;
          case 408:
            axios
              .post("/resendValidateEmail", {
                token,
              })
              .then(() => {
                setAlert({
                  alert: true,
                  msg: "Ativação expirada, novo email de ativação reenviado",
                });
              })
              .catch((error) => {
                console.log(error);
                setAlert({
                  alert: true,
                  msg: "Problema ao reenviar email de ativação",
                });
              });
            break;
          default:
            setAlert({ alert: true, msg: "Problema ao ativar conta" });
            break;
        }
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed["?activation"]) {
      activateAccount(parsed["?activation"]);
    }
    if (location?.state?.resetSent) {
      location.state = null;
      setAlertSuccess({
        alert: true,
        msg: "Email de troca de senha enviado, olhe sua caixa de entrada",
      });
    }
    if (location?.state?.resetSuccess) {
      location.state = null;
      setAlertSuccess({
        alert: true,
        msg: "Senha alterada com sucesso, entre em sua conta.",
      });
    }
  }, [location]);

  const maskCel = async (e) => {
    let v = e.target.value;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    if (v.length > 11) {
      return;
    }
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    setRegisterCell(v);
  };

  const completeGoogleLogin = async (e) => {
    e.preventDefault();
    if (!registerCell || !registerEmail || !registerName || !registerUid) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Preencha todos os campos" });
    }
    let realCell = registerCell.replace(/\D/g, "");
    if (realCell.length < 11) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Telefone inválido" });
    }

    if (!selectedPerm) {
      window.scrollTo(0, 0);
      return setAlert({
        alert: true,
        msg: "Para continuar selecione uma forma de utilização",
      });
    }

    setLoading(true);
    axios
      .post("/finish/login", {
        email: registerEmail,
        name: registerName,
        cellphone: "+55" + realCell,
        firebaseUid: registerUid,
        additionalPerm: {
          salesman: selectedPerm === "salesman",
        },
      })
      .then(({ data }) => {
        setLoading(false);
        window.localStorage.setItem("@Lockyt:token", data.token);
        if (data?.user?.additionalPerm?.salesman) {
          return history.push("/salesman/dashboard");
        }
        return history.push("/admin/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 1:
            setAlert({
              alert: true,
              msg: "Telefone já cadastro, tente outro número de telefone",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar com o google, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert.msg}
          open={alert.alert}
          closeNotification={() => setAlert({ alert: false, msg: "" })}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={ErrorIcon}
          message={alertSuccess.msg}
          open={alertSuccess.alert}
          closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
          close
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/lockytBranco.png")}
                alt="Logo lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
          <Grid item style={{ marginRight: "2%" }}>
            <InstallPWA btn={{ className: classes.installPWA }} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                {register ? (
                  <form onSubmit={completeGoogleLogin}>
                    <Typography
                      variant="h4"
                      style={{ marginBottom: "1rem" }}
                      align="center"
                    >
                      Completar cadastro
                    </Typography>
                    <FormGroup>
                      {!hasCellphone && (
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="cellphone">
                            Celular com DDD
                          </InputLabel>
                          <Input
                            id="cellphone"
                            type="tel"
                            value={registerCell}
                            disabled={loading}
                            onChange={maskCel}
                          />
                        </FormControl>
                      )}
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend">
                          Selecione a sua forma de utilização
                        </FormLabel>
                        <RadioGroup
                          aria-label="Função"
                          value={selectedPerm}
                          onChange={(e) => setSelectedPerm(e.target.value)}
                        >
                          <FormControlLabel
                            value="normal"
                            control={<Radio />}
                            label="Normal"
                          />
                          <FormControlLabel
                            value="salesman"
                            control={<Radio />}
                            label="Vendedor"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Button
                        size="small"
                        className={classes.btn}
                        type="submit"
                      >
                        Terminar cadastro
                      </Button>
                    </FormGroup>
                  </form>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        variant="h4"
                        style={{ marginBottom: "1rem" }}
                        align="center"
                      >
                        Entrar
                      </Typography>
                      <Button
                        size="small"
                        className={classes.btnGoogleContainer}
                        onClick={loginGoogle}
                      >
                        <img
                          src={require("assets/img/auth/btn_google.png")}
                          className={classes.btnGoogle}
                          alt="Login com google"
                        />
                      </Button>
                    </div>
                    <form onSubmit={firebaseLogin}>
                      <FormGroup>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="email">Email </InputLabel>
                          <Input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="password">Senha </InputLabel>
                          <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          size="small"
                          className={classes.btn}
                          type="submit"
                        >
                          Entrar
                        </Button>
                        <div className={classes.btnSecondaryContainer}>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            ou
                          </Typography>
                          <Button
                            size="small"
                            className={classes.btnSecondary}
                            onClick={() => history.push("/register")}
                          >
                            Cadastre-se
                          </Button>
                        </div>
                        <Button
                          size="small"
                          className={classes.btnForgot}
                          onClick={() => history.push("/forgot/password")}
                        >
                          <Typography variant="body2">
                            Esqueceu sua senha?
                          </Typography>
                        </Button>
                      </FormGroup>
                    </form>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Login;
