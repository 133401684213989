import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "config/axios";

import ErrorIcon from "@material-ui/icons/Error";

import image from "assets/img/bg.jpg";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles from "./styles";

function ResetPassword(props) {
  const { token } = props.match.params;
  let [loading, setLoading] = useState(false),
    [confPassword, setConfPassword] = useState(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [password, setPassword] = useState("");

  let history = useHistory(),
    classes = useStyles();

  const verify = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== confPassword) {
      return setAlert({ msg: "As senhas não são iguais", alert: true });
    }
    axios
      .post("/resetPassword", {
        password,
        token,
      })
      .then(() => {
        setLoading(false);
        history.push("/", { resetSuccess: true });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.status) {
          case 404:
            setAlert({ alert: true, msg: "Pedido de troca de senha não encontrado" });
            break;
          case 408:
            setAlert({
              alert: true,
              msg: "Troca expirada, peça novamente",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao trocar, tente novamente",
            });
            break;
        }
      });
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert.msg}
          open={alert.alert}
          closeNotification={() => setAlert({ alert: false, msg: "" })}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={ErrorIcon}
          message={alertSuccess.msg}
          open={alertSuccess.alert}
          closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
          close
        />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/lockytBranco.png")}
                alt="Logo lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  variant="h4"
                  style={{ marginBottom: "1rem" }}
                  align="center"
                >
                  Troca de senha
                </Typography>
                <form onSubmit={verify}>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">Senha </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="confPassword">
                        Confirme a senha{" "}
                      </InputLabel>
                      <Input
                        id="confPassword"
                        type="password"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                      />
                    </FormControl>
                    <Button size="small" className={classes.btn} type="submit">
                      Alterar
                    </Button>
                  </FormGroup>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default ResetPassword;
