import React from "react";

import axios from "../config/axios";

const getNewPerms = async () => {
  const { data } = await axios.get("/user/get/user/info", {
    timeout: 500,
  });
  if (data.additionalPerm) {
    window.localStorage.setItem(
      "@Lockyt:additionalPerm",
      JSON.stringify(data.additionalPerm)
    );
  }
};

window.onload = getNewPerms;

export const defaultContextValue = {
  value: () => {
    const perms = window.localStorage.getItem("@Lockyt:additionalPerm");
    try {
      return perms ? JSON.parse(perms) : {};
    } catch (error) {
      return {};
    }
  },
  updateValue: (newValue) => {
    window.localStorage.setItem(
      "@Lockyt:additionalPerm",
      JSON.stringify(newValue)
    );
    return newValue;
  },
};

export const AuthContext = React.createContext(defaultContextValue);
