import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "config/axios";

import useStyles from "./styles";

export default function ResponsiveDialog(props) {
  const price = props.price,
    rentCod = props.rentCod,
    rentPwd = props.rentPwd;
  const theme = useTheme();
  const [loading, setLoading] = useState(false),
    [cords, setCords] = useState();

  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const formatNumber = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value || 0);
  };

  useEffect(() => {
    if (!rentCod || !rentPwd || !price) {
      props.handleClose();
    }
  }, [rentCod, rentPwd, price, props]);

  const rentDoor = async () => {
    setLoading(true);
    axios
      .post("/user/rent/door", {
        doorCod: rentCod.toLowerCase(),
        doorPwd: rentPwd,
        lat: cords?.lat,
        long: cords?.long,
      })
      .then(() => {
        setLoading(false);
        props.getDoors();
        props.setAlert({
          alert: true,
          msg: `Porta ${rentCod} alugada com sucesso`,
          color: "success",
        });
        props.handleClose();
      })
      .catch((error) => {
        setLoading(false);
        props.getDoors();
        console.log(error);
        switch (error?.response?.status) {
          case 418:
            return props.setAlert({
              alert: true,
              msg: "Você está longe da porta",
              color: "danger",
            });
          case 402:
            return props.setAlert({
              alert: true,
              msg: "Problema ao realizar pagamento",
              color: "danger",
            });
          case 425:
            return props.setAlert({
              alert: true,
              msg: "Sem forma de pagamento",
              color: "danger",
            });
          case 400:
            switch (error?.response?.data?.status) {
              case 1:
                return props.setAlert({
                  alert: true,
                  msg: "Armário não ativado",
                  color: "danger",
                });
              case 400:
                return props.setAlert({
                  alert: true,
                  msg: "Porta não encontrada",
                  color: "danger",
                });
              case 408:
                return props.setAlert({
                  alert: true,
                  msg: "Tempo de abertura esgotado",
                  color: "danger",
                });
              default:
                return props.setAlert({
                  alert: true,
                  msg: "Porta não encontrada",
                  color: "danger",
                });
            }
          default:
            return props.setAlert({
              alert: true,
              msg: "Problema ao alugar porta",
              color: "danger",
            });
        }
      });
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.watchPosition(
        function (position) {
          setCords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accu: position.coords.accuracy,
          });
        },
        (error) => {
          console.log("Erro ao ler GPS: " + error);
        },
        { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
      );
    }
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.price ? true : false}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valor do aluguel"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Inicial: <b>{formatNumber(price?.basePrice)}</b>
          </Typography>
          <Typography variant="body1">
            Por hora: <b>{formatNumber(price?.multiplier)}</b>
          </Typography>
          <Typography variant="body1">
            Abertura extra: <b>{formatNumber(price?.opening)}</b>
          </Typography>
          <Typography variant="body1">
            Convidado: <b>{formatNumber(price?.access)}</b>
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={props.handleClose}
            disabled={loading}
            size="large"
            color="primary"
          >
            Voltar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={rentDoor}
              disabled={loading}
              variant="contained"
              color="primary"
              size="large"
            >
              Alugar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
