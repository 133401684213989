import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

let deferredPrompt;

export default function ResponsiveDialog() {
  let [showInstall, setShowInstall] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      if (
        window.localStorage.getItem("@Lockyt:showInstallModel") === "dismissed"
      ) {
        return;
      }
      setShowInstall(true);
    });
  }, []);

  const showPrompt = (e) => {
    // Hide the app provided install promotion
    setShowInstall(true);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  };

  const handleClose = () => {
    setShowInstall(false);
    window.localStorage.setItem("@Lockyt:showInstallModel", "dismissed");
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showInstall}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Instalar Web App?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              O Web App não acupa espaço em seu dispositivo e traz facilidade na
              utilização do Lockyt.
            </Typography>
            <Typography variant="body1" style={{ marginTop: 10 }}>
              O Lockyt ficará em sua tela inicial do celular como um aplicativo
              instalado.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não obrigado
          </Button>
          <Button onClick={showPrompt} color="primary">
            Sim instalar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
