import React, { useState, useEffect, useCallback } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Divider,
  LinearProgress,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

// custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import axios from "config/axios";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [logs, setLogs] = useState([]),
    [selectedLog, setSelectedLog] = useState(""),
    [loading, setLoading] = useState(false),
    [filterMinDate, setFilterMinDate] = useState(null),
    [filterMaxDate, setFilterMaxDate] = useState(null),
    [orderSelector, setOrderSelector] = useState(-1),
    [alert, setAlert] = useState({ alert: false, msg: "" });

  const getData = useCallback(
    async (e, clear) => {
      setLoading(true);
      axios
        .get(
          `/user/get/logs/${clear ? "0" : filterMinDate || "0"}/${
            clear ? "0" : filterMaxDate || "0"
          }`
        )
        .then(({ data }) => {
          setLoading(false);
          data.forEach((e) => {
            e.creation = e.createdAt;
            e.createdAt = moment(e.createdAt).format("DD/MM/YYYY - HH:mm");
            e.closed.sort();
            e.opened.sort();
            if (e.board && e.board.address) {
              e.address = e?.board?.address.split("-")[0];
            } else {
              e.address = "---";
            }
            if (e.ended) {
              e.status = "Encerrado";
            } else {
              e.status = "Em andamento";
            }
          });
          setLogs(data);
        })
        .catch((error) => {
          setLoading(false);
          let status = error?.response?.status;
          switch (status) {
            case 401:
              setAlert({ alert: true, msg: "Não autorizado" });
              break;
            default:
              setAlert({ alert: true, msg: "Problema ao carregar histórico" });
              break;
          }
          console.log(error);
        });
    },
    [filterMaxDate, filterMinDate]
  );

  /* UseEffect que carrega os logs */
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const diff_hours = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.ceil(diff));
  };

  const changeOrder = (e) => {
    let res;
    switch (e.target.value) {
      case 1:
        res = logs.sort(function (a, b) {
          var dateA = new Date(a.creation).getTime(),
            dateB = new Date(b.creation).getTime();
          return dateA - dateB;
        });
        setLogs(res);
        break;
      case -1:
        res = logs.sort(function (a, b) {
          var dateA = new Date(a.creation).getTime(),
            dateB = new Date(b.creation).getTime();
          return dateB - dateA;
        });
        setLogs(res);
        break;
      default:
        break;
    }
    setOrderSelector(e.target.value);
  };

  const renderDetails = async (log) => {
    setSelectedLog(log);
  };

  const calculatePrice = useCallback(() => {
    let price,
      amountOpened = selectedLog?.opened?.length,
      accessCount = selectedLog?.guestUsers.length;

    let priceOpen = selectedLog?.price?.opening || 2.5,
      priceAccess = selectedLog?.price?.access || 2.5,
      priceRent = selectedLog?.price?.basePrice || 5,
      multi = selectedLog?.price?.multiplier || 0.5;

    price = amountOpened * priceOpen + priceRent + accessCount * priceAccess;
    if (selectedLog.ended) {
      price =
        price +
        multi *
          diff_hours(new Date(selectedLog.ended), new Date(selectedLog.rented));
    } else {
      price =
        price + multi * diff_hours(new Date(), new Date(selectedLog.rented));
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(price);
  }, [selectedLog]);

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() => setAlert({ alert: false, msg: "" })}
        close
      />
      {selectedLog ? (
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: 20 }}>
            <IconButton
              color="primary"
              className={classes.iconBtnBack}
              onClick={() => setSelectedLog(null)}
            >
              <ArrowBack className={classes.iconBack} />
            </IconButton>
            <Card className={classes.root}>
              <CardContent>
                <Typography variant="body2">Porta</Typography>
                <Typography variant="h4">{selectedLog?.door?.cod}</Typography>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginTop: "0.5rem" }}
                >
                  Alugada em: <b>{selectedLog.createdAt}</b>
                </Typography>
                <Divider
                  variant="fullWidth"
                  component="hr"
                  className={classes.divider}
                />
                <div className={classes.dataContainer}>
                  {selectedLog.opened.length > 0 && (
                    <Typography variant="h5" align="center">
                      Suas aberturas
                    </Typography>
                  )}
                  <div className={classes.data}>
                    {selectedLog.opened.map((val, index) => (
                      <Typography variant="body1" key={index} align="center">
                        {index + 1 + "º"} -{" "}
                        <b>{moment(val).format("DD/MM/YYYY - HH:mm")}</b>
                      </Typography>
                    ))}
                  </div>
                  {selectedLog.guestUsers.length > 0 &&
                    selectedLog.opened.length > 0 && (
                      <Divider
                        variant="fullWidth"
                        component="hr"
                        className={classes.divider}
                      />
                    )}
                  {selectedLog?.guestUsers.length > 0 && (
                    <Typography variant="h5" align="center">
                      Convidados adicionados
                    </Typography>
                  )}
                  <div className={classes.data}>
                    {selectedLog?.guestUsers.map((val, index) => (
                      <Typography variant="body1" key={index} align="center">
                        {index + 1 + "º"} - <b>{val?.guest?.name}</b>
                      </Typography>
                    ))}
                  </div>
                  {selectedLog?.guestUsers.length > 0 && (
                    <Divider
                      variant="fullWidth"
                      component="hr"
                      className={classes.divider}
                    />
                  )}
                  {selectedLog?.guestUsers.length > 0 && (
                    <Typography variant="h5" align="center">
                      Aberturas dos convidados
                    </Typography>
                  )}
                  <div className={classes.data}>
                    {selectedLog?.guest_opened.map((val, index) => (
                      <Typography variant="body1" key={index} align="center">
                        {index + 1 + "º"} - <b>{val?.guest?.name}</b> -{" "}
                        <b>{moment(val?.date).format("DD/MM/YYYY - HH:mm")}</b>
                      </Typography>
                    ))}
                    {selectedLog?.guest_opened.length === 0 &&
                      selectedLog.guestUsers.length > 0 && (
                        <Typography variant="body1" align="center">
                          Convidado não abriu a porta
                        </Typography>
                      )}
                  </div>
                  {selectedLog.guestUsers.length > 0 && (
                    <Divider
                      variant="fullWidth"
                      component="hr"
                      className={classes.divider}
                    />
                  )}
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginTop: "1rem" }}
                  >
                    Encerrado por: <b>{selectedLog?.endedBy?.name}</b>{" "}
                    {selectedLog?.endedBy?.name && "- "}
                    <b>
                      {selectedLog.ended
                        ? moment(selectedLog.ended).format("DD/MM/YYYY - HH:mm")
                        : "Em andamento"}
                    </b>
                  </Typography>
                </div>
              </CardContent>
              <CardActions>
                <Typography variant="h5" align="center">
                  Valor {selectedLog.ended ? "cobrado" : "atual"}:{" "}
                  {calculatePrice()}
                </Typography>
              </CardActions>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <>
          {loading && <LinearProgress />}
          <GridContainer>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <GridItem xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item>
                    <KeyboardDatePicker
                      margin="normal"
                      id="minDate"
                      label="Data mínima"
                      format="dd/MM/yyyy"
                      value={filterMinDate}
                      onChange={(date) =>
                        setFilterMinDate(() => {
                          try {
                            return date.toISOString();
                          } catch (err) {
                            return date;
                          }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "Mudar data mínima",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker
                      margin="normal"
                      id="maxDate"
                      label="Data máxima"
                      format="dd/MM/yyyy"
                      value={filterMaxDate}
                      onChange={(date) =>
                        setFilterMaxDate(() => {
                          try {
                            return date.toISOString();
                          } catch (err) {
                            return date;
                          }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "Mudar data máxima",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink id="order-selector-label">
                        Ordem
                      </InputLabel>
                      <Select
                        labelId="order-selector"
                        id="order-selector"
                        value={orderSelector}
                        displayEmpty={true}
                        onChange={changeOrder}
                      >
                        <MenuItem value={-1}>Mais recente</MenuItem>
                        <MenuItem value={1}>Mais antigo</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {(filterMaxDate || filterMinDate) && (
                    <>
                      <Grid item xs={12}>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={getData}
                            startIcon={<SearchIcon />}
                          >
                            Filtrar
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setFilterMinDate(null);
                              setFilterMaxDate(null);
                              getData(null, true);
                            }}
                            startIcon={<CloseIcon />}
                          >
                            Limpar
                          </Button>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </GridItem>
            </MuiPickersUtilsProvider>
            {logs.length === 0 && (
              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="body1" align="center">
                      Sem histórico
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
            )}
            {logs.map((item, index) => (
              <GridItem xs={12} style={{ marginTop: 20 }} key={index}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="body2">Porta</Typography>
                    <Typography variant="h4">{item?.door?.cod}</Typography>
                    <div className={classes.dataContainer}>
                      <Typography variant="body1">
                        Status: <b>{item.status}</b>
                      </Typography>
                      <Typography variant="body1">
                        Alugada em: <b>{item?.createdAt}</b>
                      </Typography>
                      <Typography variant="body1">
                        Endereço: <b>{item.address}</b>
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => renderDetails(item)}
                      variant="contained"
                      color="secondary"
                    >
                      Detalhes
                    </Button>
                  </CardActions>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </>
      )}
    </div>
  );
}
