import React, { useState, useContext } from "react";

import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@material-ui/core";

import logOut from "functions/logOut";

import MenuIcon from "@material-ui/icons/Menu";
import ExitIcon from "@material-ui/icons/ExitToApp";

import { AuthContext } from "context/AuthContext";

import { salesmanRoutes, promoRoutes } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    minWidth: 220,
  },
  content: {
    marginTop: "15px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  },
}));

export default function ButtonAppBar() {
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();

  let history = useHistory();
  const authContext = useContext(AuthContext);

  const toggleDrawer = () => {
    setDrawer((oldState) => !oldState);
  };

  const switchRoutes = (
    <Switch>
      {salesmanRoutes.map((prop, key) => {
        if (prop.layout === "/salesman") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      {authContext.value().promoControl &&
        promoRoutes.map((prop, key) => {
          if (prop.layout === "/salesman") {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
          return null;
        })}
      <Redirect from="/salesman" to={`/saleman${salesmanRoutes[0].path}`} />
    </Switch>
  );

  const itemClick = (route) => {
    history.push(`${route.layout}${route.path}`);
    setDrawer(false);
  };

  const renderList = () => (
    <List className={classes.list}>
      {salesmanRoutes.map((route, index) => (
        <ListItem button key={index} onClick={() => itemClick(route)}>
          <ListItemIcon>
            {typeof route.icon === "string" ? (
              <Icon style={{ color: "#000000DE" }}>{route.icon}</Icon>
            ) : (
              <route.icon style={{ color: "#000000DE" }} />
            )}
          </ListItemIcon>
          <ListItemText primary={route.name} />
        </ListItem>
      ))}
      {authContext.value().promoControl &&
        promoRoutes.map((route, index) => (
          <ListItem button key={index} onClick={() => itemClick(route)}>
            <ListItemIcon>
              {typeof route.icon === "string" ? (
                <Icon style={{ color: "#000000DE" }}>{route.icon}</Icon>
              ) : (
                <route.icon style={{ color: "#000000DE" }} />
              )}
            </ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        ))}
    </List>
  );

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              style={{ textTransform: "none" }}
              className={classes.title}
            >
              Olá, vendedor
            </Typography>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={logOut}
            >
              <ExitIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={drawer} onClose={toggleDrawer}>
          {renderList()}
        </Drawer>
      </div>
      <div className={classes.content}>
        <div className={classes.container}>{switchRoutes}</div>
      </div>
    </>
  );
}
