import React, { useState, useEffect } from "react";
import Cards from "react-credit-cards";
import moment from "moment";

import axios from "config/axios";

/* Material UI icons */
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EventIcon from "@material-ui/icons/Event";
import Lock from "@material-ui/icons/Lock";
import ErrorIcon from "@material-ui/icons/Error";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Cached from "@material-ui/icons/Cached";

//Material UI Core
import {
  Button,
  IconButton,
  Typography,
  CardActions,
  CardContent,
  FormControlLabel,
  Checkbox,
  Card,
  Paper,
  Grid,
  InputAdornment,
  CircularProgress,
  Input,
} from "@material-ui/core";

import Select from "react-select";

//Custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import validateCPF from "functions/validateCPF";

import useStyles from "./styles";

const selectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#8a6eb2" : isFocused && "#05b5a9",
      color: isSelected ? "white" : isFocused && "white",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
      },
    };
  },
};

function Payment() {
  const [CardNumber, setCardNumber] = useState(""),
    [Holder, setHolder] = useState(""),
    [ExpirationDate, setExpirationDate] = useState(""),
    [Brand, setBrand] = useState(""),
    [Cvc, setCvc] = useState(""),
    [showCardForm, setShowCardForm] = useState(false),
    [cards, setCards] = useState([]),
    [alert, setAlert] = useState({ alert: false, msg: "" }),
    [alertSuccess, setAlertSuccess] = useState({ alert: false, msg: "" }),
    [creditOptions] = useState([
      { value: 1, label: "R$1,00" },
      { value: 10, label: "R$10,00" },
      { value: 20, label: "R$20,00" },
      { value: 50, label: "R$50,00" },
      { value: 100, label: "R$100,00" },
    ]),
    [selectedPicpayCredit, setSelectedPicpayCredit] = useState(),
    [selectedCardCredit, setSelectedCardCredit] = useState(),
    [userInfo, setUserInfo] = useState(),
    [picpayLoading, setPicpayLoading] = useState(false),
    [cardLoading, setCardLoading] = useState(false),
    [document, setDocument] = useState(""),
    [inputFocused, setInputFocused] = useState("");
  const classes = useStyles();

  const handleInputFocus = (e) => {
    setInputFocused(e.target.name);
  };

  const enterExpireDate = async (e) => {
    if (e.target.value === " ") {
      return;
    }
    if (e.target.value.length < 7) {
      let value = e.target.value.replace(/(\d{2})(\d{4})/, "$1/$2");
      value = value.replace(/[^/0-9]+/, "");
      setExpirationDate(value);
    }
  };

  const handleCVC = (e) => {
    if (e.target.value.length < 5) {
      let value = e.target.value.replace(/\D+/, "");
      setCvc(value);
    }
  };

  const getData = async () => {
    axios
      .get("/user/get/cards")
      .then(({ data }) => {
        setCards(data);
      })
      .catch((error) => {
        let status = error?.response?.status;
        switch (status) {
          case 401:
            setAlert({ alert: true, msg: "Não autorizado" });
            break;
          default:
            setAlert({ alert: true, msg: "Problema ao carregar cartões" });
            break;
        }
        console.log(error);
      });
  };

  const getUserInfo = async () => {
    axios
      .get("/user/get/user/info")
      .then(({ data }) => {
        setUserInfo(data);
      })
      .catch((error) => {
        setAlert({ alert: true, msg: "Problema ao carregar dados da conta" });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getUserInfo();
  }, []);

  const procPrimaryCard = async (item) => {
    let result = [];
    cards.forEach((e) => {
      if (e.primaryCard === true) {
        e.primaryCard = false;
      }
      if (e._id === item._id) {
        e.primaryCard = true;
      }
      result.push(e);
    });
    axios
      .put("/user/alter/primary/card", {
        newPrimary: item._id,
      })
      .then(() => {
        setCards(result);
      })
      .catch((error) => {
        console.log(error);
        switch (error.response.status) {
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao atualizar cartão em uso",
            });
        }
      });
  };

  const addCard = async () => {
    if (!Brand || !ExpirationDate || !Holder || !CardNumber || !Cvc) {
      return setAlert({ alert: true, msg: "Preencha todos os campos" });
    }
    if (CardNumber.length < 13) {
      return setAlert({ alert: true, msg: "Cartão inválido" });
    }
    axios
      .post("/user/add/card", {
        Brand,
        ExpirationDate,
        Holder,
        CardNumber,
        SecurityCode: Cvc,
      })
      .then(() => {
        setAlertSuccess({ alert: true, msg: "Cadastrado com sucesso" });
        setShowCardForm(false);
        getData();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          switch (error?.response?.data?.error[0]?.Code) {
            case 999:
              return setAlert({
                alert: true,
                msg: "Bandeira do cartão inválida",
              });
            case 126:
              return setAlert({
                alert: true,
                msg: "Data inválida",
              });
            default:
              return setAlert({
                alert: true,
                msg: "Cartão inválido",
              });
          }
        }
        switch (error?.response?.status) {
          case 401:
            setAlert({
              alert: true,
              msg: "Cartão de uso único já consumido",
            });
            break;
          case 404:
            setAlert({
              alert: true,
              msg: "Usuário não encontrado",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Erro ao cadastrar cartão",
            });
            break;
        }
      });
  };

  const renderCard = () => {
    return (
      <div id="PaymentForm">
        <Card>
          <CardContent>
            <IconButton
              color="secondary"
              onClick={() => setShowCardForm(false)}
              aria-label="Voltar"
            >
              <KeyboardArrowLeft fontSize="large" />
            </IconButton>
            <h5 className={classes.center}>Cadastrar cartão de crédito</h5>
            <Cards
              expiry={ExpirationDate}
              focused={inputFocused}
              name={Holder}
              number={CardNumber}
              callback={(e) => setBrand(e.issuer)}
              cvc={Cvc}
              className="Cards"
            />
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Nome como no cartão"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "name",
                    onChange: (e) => setHolder(e.target.value),
                    value: Holder,
                    onFocus: handleInputFocus,
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountBoxIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Número do cartão"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "tel",
                    name: "card",
                    onChange: (e) => setCardNumber(e.target.value),
                    value: CardNumber,
                    onFocus: handleInputFocus,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreditCardIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Expiração"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  id="expireDate"
                  inputProps={{
                    type: "text",
                    name: "expiry",
                    onChange: enterExpireDate,
                    value: ExpirationDate,
                    onFocus: handleInputFocus,
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="CVV"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  id="cvv"
                  inputProps={{
                    type: "text",
                    name: "cvc",
                    onChange: handleCVC,
                    value: Cvc,
                    onFocus: handleInputFocus,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardContent>
          <CardFooter className={classes.center}>
            <Button variant="contained" color="secondary" onClick={addCard}>
              Cadastrar
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  };

  const changePicpayCreditSelection = async (data) => {
    setSelectedPicpayCredit(data.value);
  };

  const addPicpayCredit = async () => {
    if (!document) {
      return setAlert({ alert: true, msg: "Entre com o seu CPF" });
    }

    if (!(await validateCPF(document))) {
      return setAlert({
        alert: true,
        msg: "CPF inválido, entre com um CPF válido",
      });
    }
    if (!selectedPicpayCredit) {
      return setAlert({ alert: true, msg: "Selecione uma opção" });
    }
    setPicpayLoading(true);
    axios
      .post("/user/add/picpay/credits", {
        amount: selectedPicpayCredit,
        document: await validateCPF(document),
      })
      .then(() => {
        setPicpayLoading(false);
        setAlertSuccess({ alert: true, msg: "Pedido realizado com sucesso" });
      })
      .catch((error) => {
        console.log(error);
        setPicpayLoading(false);
        setAlert({ alert: true, msg: "Problema ao realizar pedido" });
      });
  };

  const changeCardCreditSelection = async (data) => {
    setSelectedCardCredit(data.value);
  };

  const addCardCredit = async () => {
    if (!selectedCardCredit) {
      return setAlert({ alert: true, msg: "Selecione uma opção" });
    }
    setCardLoading(true);
    axios
      .post("/user/add/card/credits", {
        amount: selectedCardCredit,
      })
      .then(() => {
        setCardLoading(false);
        setAlertSuccess({
          alert: true,
          msg: "Pagamento realizado com sucesso",
        });
      })
      .catch((error) => {
        setCardLoading(false);
        console.log(error);
        setAlert({ alert: true, msg: "Problema ao pagar com cartão" });
      });
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() => setAlert({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={ErrorIcon}
        message={alertSuccess.msg}
        open={alertSuccess.alert}
        closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
        close
      />
      {showCardForm ? (
        renderCard()
      ) : (
        <>
          <Paper style={{ padding: 10 }} elevation={0}>
            <GridContainer>
              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      className={classes.textScale}
                      aling="center"
                    >
                      Seus créditos:{" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(userInfo?.credits || 0)}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                    <IconButton onClick={getUserInfo} alt="Recarregar créditos">
                      <Cached />
                    </IconButton>
                  </Grid>
                </Grid>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Paper className={classes.paper} elevation={2}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        src={require("assets/img/payment/picpay.png")}
                        className={classes.picpayImg}
                        alt="Logomarca Picpay"
                      />
                    </Grid>
                    <Grid item style={{ marginLeft: 10 }}>
                      <Typography variant="h5">Picpay</Typography>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: 10, textAlign: "center" }}>
                    <Input
                      placeholder="Seu CPF"
                      value={document}
                      id="document"
                      required
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length > 11) {
                          return;
                        }
                        setDocument(value);
                      }}
                      style={{ marginBottom: 10 }}
                    />
                    <Select
                      options={creditOptions}
                      onChange={changePicpayCreditSelection}
                      //value={defaultOption}
                      placeholder="Selecionar valor"
                      className={classes.selector}
                      styles={selectStyles}
                    />
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ marginTop: 10 }}
                    >
                      Você receberá um email ou notificação através do PicPay
                    </Typography>
                    <div className={classes.wrapper}>
                      <Button
                        style={{ marginTop: "1rem", textAlign: "center" }}
                        variant="contained"
                        color="secondary"
                        disabled={picpayLoading}
                        onClick={addPicpayCredit}
                      >
                        Adicionar
                      </Button>
                      {picpayLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Paper>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Paper className={classes.paper} elevation={2}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        src={require("assets/img/payment/cielo.png")}
                        className={classes.cieloImg}
                        alt="Logomarca Cielo"
                      />
                    </Grid>
                    <Grid item style={{ marginLeft: 7 }}>
                      <Typography variant="h5">Cartão</Typography>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: 10, textAlign: "center" }}>
                    <Select
                      options={creditOptions}
                      onChange={changeCardCreditSelection}
                      //value={defaultOption}
                      placeholder="Selecionar valor"
                      className={classes.selector}
                      styles={selectStyles}
                    />
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ marginTop: 10 }}
                    >
                      O valor será retirado do seu cartão em uso
                    </Typography>

                    <div className={classes.wrapper}>
                      <Button
                        style={{ marginTop: "1rem", textAlign: "center" }}
                        variant="contained"
                        color="secondary"
                        onClick={addCardCredit}
                        disabled={cards.length === 0 || cardLoading}
                        disableRipple={true}
                      >
                        Adicionar
                      </Button>
                      {cardLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Paper>
              </GridItem>
            </GridContainer>
          </Paper>
          <Grid container>
            {cards.length !== 0 && (
              <GridItem
                xs={12}
                style={{ marginBottom: "0.5rem", marginTop: "2rem" }}
              >
                <div className={classes.center}>
                  <Button
                    onClick={() => setShowCardForm(true)}
                    color="secondary"
                    variant="contained"
                  >
                    Adicionar cartão
                  </Button>
                </div>
              </GridItem>
            )}
            {cards.length === 0 && (
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Card className={classes.root}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h5"
                      style={{ fontSize: 19 }}
                      align="center"
                    >
                      Nenhum cartão de crédito cadastrado
                    </Typography>
                    <Button
                      style={{ marginTop: "1rem" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => setShowCardForm(true)}
                    >
                      Cadastrar cartão
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {cards.map((item, index) => (
              <Grid item xs={12} key={index} style={{ marginTop: 20 }}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="body1">
                      Cartão: <b>{item.cardEnd}</b>
                    </Typography>
                    <div className={classes.dataContainer}>
                      <Typography variant="body1">
                        Nome no cartão: <b>{item?.holder}</b>
                      </Typography>
                      <Typography variant="body1">
                        Cadastrado em:{" "}
                        <b>{moment(item.address).format("DD/MM/YYYY")}</b>
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          checked={item.primaryCard}
                          disabled={item.primaryCard && true}
                          onChange={() => procPrimaryCard(item)}
                          name="checkedB"
                          color="secondary"
                        />
                      }
                      label={
                        item.primaryCard ? "Cartão em uso" : "Utilizar cartão"
                      }
                      style={{ color: "black" }}
                    />
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}

export default Payment;
