import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";

import image from "assets/img/bg.jpg";

//custom components
import InstallPWA from "components/InstallPWA/InstallPWA";

import useStyles from "./styles";

function Support() {
  const classes = useStyles();

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/lockytBranco.png")}
                alt="Logomarca lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
          <Grid item style={{ marginRight: "2%" }}>
            <InstallPWA btn={{ className: classes.installPWA }} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <h2>O Lockyt valoriza o seu tempo!</h2>
                <p>
                  Um guarda volumes seguro e prático, um delivery inteligente,
                  um sistema inovador e dinâmico. Aproveite uma nova experiência
                  usando a tecnologia Lockyt.
                </p>{" "}
                <ol>
                  <li className={classes.bold}>
                    Baixe nosso App Lockyt pelas lojas do seu dispositivo ou
                    opere diretamente pela nossa plataforma (WEB APP) no site{" "}
                    <a
                      href={window.location.origin}
                      style={{
                        textTransform: "lowercase",
                        fontWeight: "bold",
                      }}
                    >
                      https://use.lockyt.com
                    </a>
                    ;
                  </li>
                  <li className={classes.bold}>
                    Em caso de delivery a encomenda já está à sua espera!
                    Selecione a porta e resgate seu pacote diretamente no App
                    Lockyt, ou;
                  </li>
                  <li className={classes.bold}>
                    Insira seu cartão de crédito para adicionar créditos Lockyt.
                    Agora é só escolher a porta e aproveitar o seu guarda
                    volumes.
                  </li>
                </ol>
                <p>
                  Para dúvidas, questionamentos ou sugestões, acesse{" "}
                  <a
                    href="https://microsistemas.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                    }}
                  >
                    https://microsistemas.com.br
                  </a>{" "}
                  ou entre em contato conosco via email{" "}
                  <a
                    href="mailto:service@microsistemas.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                    }}
                  >
                    service@microsistemas.com.br
                  </a>{" "}
                  ou
                  <a
                    href="https://wa.me/message/E4EV63KYSO3ZP1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    WhastApp: +55(41) 98848-5382
                  </a>
                  .
                </p>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item className={classes.gridLogos}>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.lockyt.use.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Disponível no Google Play"
                        src={require("assets/img/badges/badgeGoogle.png")}
                        className={classes.googleBadge}
                      />
                    </a>
                  </Grid>
                  <Grid item className={classes.gridLogos}>
                    <a
                      href="https://apps.apple.com/us/app/lockyt/id1538302823"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Disponível na App Store"
                        src={require("assets/img/badges/badgeApple.png")}
                        className={classes.appleBadge}
                      />
                    </a>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Support;
