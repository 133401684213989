import React from "react";

import { Grid } from "@material-ui/core";

import InstallPWA from "components/InstallPWA/InstallPWA";

import useStyles from "./styles";

function Header() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      className={classes.logos}
    >
      <Grid item className={classes.gridLogos}>
        <a
          href="https://lockyt.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("assets/img/lockytBranco.png")}
            alt="Logo lockyt"
            className={classes.logoLockyt}
          />
        </a>
      </Grid>
      <Grid item style={{ marginRight: "2%" }}>
        <InstallPWA btn={{ className: classes.installPWA }} />
      </Grid>
    </Grid>
  );
}

export default Header;
