import React, { useState, useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "config/axios";

import ErrorIcon from "@material-ui/icons/Error";
import ArrowBack from "@material-ui/icons/ArrowBack";

import isAuthenticated from "functions/isAuthenticated";

import fire from "config/firebase";

import image from "assets/img/bg.jpg";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles from "./styles";

function ResetPassword(props) {
  const { token } = props.match.params;
  let [loading, setLoading] = useState(false),
    [confDoorPassword, setConfDoorPassword] = useState(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [logged, setLogged] = useState(false),
    [doorPassword, setDoorPassword] = useState(""),
    [password, setPassword] = useState(""),
    [email, setEmail] = useState("");

  let history = useHistory(),
    classes = useStyles();

  const verify = async (e) => {
    e.preventDefault();
    if (doorPassword !== confDoorPassword) {
      return setAlert({ msg: "As senhas não são iguais", alert: true });
    }
    setLoading(true);
    axios
      .post("/user/reset/door/pwd", {
        password: doorPassword,
        token,
      })
      .then(() => {
        setLoading(false);
        setAlertSuccess({ alert: true, msg: "Senha trocada com sucesso" });
        setTimeout(() => {
          history.push("/admin/dashboard");
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 1:
            setAlert({
              alert: true,
              msg: "Pedido de troca de senha não encontrado",
            });
            break;
          case 2:
            setAlert({
              alert: true,
              msg: "Troca expirada, peça novamente",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao trocar, tente novamente",
            });
            break;
        }
      });
  };

  const loginGoogle = async () => {
    setLoading(true);
    fire
      .googleLogin()
      .then(async (val) => {
        axios
          .post("/login", {
            firebaseToken: await val.user.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            localStorage.setItem("@Lockyt:token", data.token);
            setLogged(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar com o google, tente novamente",
                  color: "danger",
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/unauthorized-domain":
            setAlert({
              alert: true,
              msg: "Domínio não autorizado",
              color: "danger",
            });
            break;
          case "auth/cancelled-popup-request":
            return;
          case "auth/popup-closed-by-user":
            return;
          case "auth/network-request-failed":
            setAlert({
              alert: true,
              msg: "Se conecte a internet para fazer login",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar com o google, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    fire
      .emailLogin(email, password)
      .then(async (val) => {
        if (!val.user.emailVerified) {
          setLoading(false);
          return setAlert({
            alert: true,
            msg: "Email não verificado",
            color: "danger",
          });
        }
        axios
          .post("/login", {
            firebaseToken: await val.user.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            localStorage.setItem("@Lockyt:token", data.token);
            setLogged(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar, tente novamente",
                  color: "danger",
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/wrong-password":
            setAlert({
              alert: true,
              msg: "Senha incorreta",
              color: "danger",
            });
            break;
          case "auth/user-not-found":
            setAlert({
              alert: true,
              msg: "Usuário não encontrado! CADASTRE-SE no Lockyt",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      setLogged(true);
    }
  }, []);

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert.msg}
          open={alert.alert}
          closeNotification={() => setAlert({ alert: false, msg: "" })}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={ErrorIcon}
          message={alertSuccess.msg}
          open={alertSuccess.alert}
          closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
          close
        />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/lockytBranco.png")}
                alt="Logo lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          {logged ? (
            <Grid item>
              <Card className={classes.root}>
                <CardContent>
                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <Button
                      startIcon={<ArrowBack />}
                      onClick={() => history.push("/admin/dashboard")}
                    >
                      Voltar ao painel
                    </Button>
                  </div>
                  <Typography
                    variant="h4"
                    style={{ marginBottom: "1rem" }}
                    align="center"
                  >
                    Troca de senha da porta
                  </Typography>
                  <form onSubmit={verify}>
                    <FormGroup>
                      <FormControl className={classes.input}>
                        <InputLabel htmlFor="password">Senha nova</InputLabel>
                        <Input
                          id="password"
                          type="password"
                          value={doorPassword}
                          disabled={loading}
                          onChange={(e) => setDoorPassword(e.target.value)}
                        />
                      </FormControl>
                      <FormControl className={classes.input}>
                        <InputLabel htmlFor="confPassword">
                          Confirme a senha nova
                        </InputLabel>
                        <Input
                          id="confPassword"
                          type="password"
                          value={confDoorPassword}
                          disabled={loading}
                          onChange={(e) => setConfDoorPassword(e.target.value)}
                        />
                      </FormControl>
                      <Button
                        size="small"
                        className={classes.btn}
                        type="submit"
                        disabled={loading}
                      >
                        Alterar
                      </Button>
                    </FormGroup>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Grid item>
              <Card className={classes.root}>
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <Typography
                      variant="h5"
                      style={{ marginBottom: "1rem" }}
                      align="center"
                    >
                      Entre em sua conta para trocar a senha da porta
                    </Typography>
                    <Button
                      size="small"
                      className={classes.btnGoogleContainer}
                      onClick={loginGoogle}
                    >
                      <img
                        src={require("assets/img/auth/btn_google.png")}
                        className={classes.btnGoogle}
                        alt="Login com google"
                      />
                    </Button>
                  </div>
                  <form onSubmit={login}>
                    <FormGroup>
                      <FormControl className={classes.input}>
                        <InputLabel htmlFor="email">Email </InputLabel>
                        <Input
                          id="email"
                          type="email"
                          value={email}
                          disabled={loading}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl className={classes.input}>
                        <InputLabel htmlFor="password">Senha </InputLabel>
                        <Input
                          id="password"
                          type="password"
                          value={password}
                          disabled={loading}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormControl>
                      <Button
                        size="small"
                        className={classes.btn}
                        disabled={loading}
                        type="submit"
                      >
                        Entrar
                      </Button>
                    </FormGroup>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default ResetPassword;
