import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: 15,
    },
    emailText: {
      fontSize: 22,
    },
    cardGridGreen: {
      borderColor: "green",
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 5,
      marginTop: 20,
    },
    cardGridBlue: {
      borderColor: "#0020f0",
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 5,
      marginTop: 20,
    },
    caughtText: {
      fontSize: 16,
      textAlign: "center",
      position: "absolute",
      left: "38%",
      right: "38%",
    },
    optionText: {
      fontSize: "2.3vh",
    },
    optionPaper: {
      padding: 15,
    },
    optionCard: {
      marginTop: 15,
    },
    iconBtnBack: {
      backgroundColor: "white",
      justifyContent: "center",
      marginBottom: "1rem",
    },
    center: {
      textAlign: "center",
      justifyContent: "center",
    },
  })
);

export default useStyles;
