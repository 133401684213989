import React, { useState, useEffect, useMemo } from "react";

import {
  Button,
  Typography,
  LinearProgress,
  Grid,
  Paper,
  CardContent,
  CardActions,
  Card,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import ArrowBack from "@material-ui/icons/ArrowBack";
import EmailIcon from "@material-ui/icons/Email";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";

import moment from "moment";

import axios from "config/axios";

import Snackbar from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput";

import isEmail from "functions/isEmail";

import useStyles from "./styles";

function ListDeliveries() {
  const [deliveries, setDeliveries] = useState([]),
    [alert, setAlert] = useState(""),
    [selected, setSelected] = useState(),
    [alertSuccess, setAlertSuccess] = useState(""),
    [newEmail, setNewEmail] = useState(""),
    [confNewEmail, setConfNewEmail] = useState(""),
    [newCellphone, setNewCellphone] = useState(""),
    [confNewCellphone, setConfNewCellphone] = useState(""),
    [loading, setLoading] = useState(false);

  const classes = useStyles();

  const getData = async () => {
    setLoading((oldState) => !oldState);

    axios
      .get("/salesman/deliveries")
      .then(({ data }) => {
        setDeliveries(data);
        setLoading((oldState) => !oldState);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        const statusError = error?.response?.data?.status;
        switch (statusError) {
          case 1:
            break;
          default:
            setAlert("Problema ao listar entregas");
            break;
        }
      });
  };

  const updateEmail = async () => {
    if (!newEmail || !confNewEmail) {
      return setAlert("Preencha os campos de email");
    }
    if (newEmail !== confNewEmail) {
      return setAlert("Os emails são diferentes");
    }
    if (!isEmail(newEmail)) {
      return setAlert("Email inválido");
    }
    setLoading((oldState) => !oldState);
    axios
      .put("/salesman/alter/mail", {
        newEmail,
        deliveryId: selected._id,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlertSuccess("Email atualizado e reenviado");
        setNewEmail("");
        setConfNewEmail("");
        setSelected(null);
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        const statusError = error?.response?.data?.status;
        switch (statusError) {
          case 1:
            setAlert("Encomenda não encontrada");
            break;
          default:
            setAlert("Problema ao atualizar entrega");
            break;
        }
      });
  };

  const updateCellphone = async () => {
    if (!newCellphone || !confNewCellphone) {
      return setAlert("Preencha os campos de celular");
    }
    if (newCellphone !== confNewCellphone) {
      return setAlert("Os celulares são diferentes");
    }
    if (newCellphone.length !== 11) {
      return setAlert("Celular inválido");
    }
    setLoading((oldState) => !oldState);
    axios
      .put("/salesman/alter/cellphone", {
        newCellphone: "+55" + newCellphone,
        deliveryId: selected._id,
      })
      .then(() => {
        setAlertSuccess("Celular atualizado e reenviado");
        setNewCellphone("");
        setConfNewCellphone("");
        setLoading((oldState) => !oldState);
        setSelected(null);
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        const statusError = error?.response?.data?.status;
        switch (statusError) {
          case 1:
            setAlert("Encomenda não encontrada");
            break;
          default:
            setAlert("Problema ao atualizar entrega");
            break;
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const procCellNumber = async (e) => {
    let value = e.target.value,
      name = e.target.name;
    value = value.replace(/\D/, "");
    if (value.length > 11) {
      return;
    }
    if (name === "newCellphone") {
      setNewCellphone(value);
    } else {
      setConfNewCellphone(value);
    }
  };

  const formatCellphone = useMemo(() => {
    try {
      let final = selected?.nonUser?.cellphone;
      final = final.substring(3);
      final = final.replace(/(\d{2})(\d{5})(\d{4})/, function (
        regex,
        arg1,
        arg2,
        arg3
      ) {
        return `(${arg1}) ${arg2}-${arg3}`;
      });
      return final ? final : "---";
    } catch (error) {
      return "---";
    }
  }, [selected]);

  return (
    <div>
      <Snackbar
        place="tc"
        color={"danger"}
        icon={ErrorIcon}
        message={alert ? alert : ""}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess ? alertSuccess : ""}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      {loading && <LinearProgress />}
      {selected ? (
        <>
          <IconButton
            color="primary"
            className={classes.iconBtnBack}
            onClick={() => {
              setSelected(null);
              setNewEmail("");
              setConfNewEmail("");
              setNewCellphone("");
              setConfNewEmail("");
            }}
          >
            <ArrowBack className={classes.iconBack} />
          </IconButton>
          <Paper className={classes.optionPaper}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item style={{ alignSelf: "center" }}>
                <Typography variant="h5" align="center">
                  Informações da entrega
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.optionText}>
                  Email: <b>{selected?.nonUser?.email}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.optionText}>
                  Telefone: <b>{formatCellphone}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.optionText}>
                  Código da entrega: <b>{selected?.cod}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.optionText}>
                  Data da entrega:{" "}
                  <b>{moment(selected.createdAt).format("DD/MM/YY HH:mm")}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.optionText}>
                  Armário:{" "}
                  <b>
                    {selected?.board.address
                      ? selected?.board?.address.split("-")[0]
                      : "-"}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.optionText}>
                  Porta: <b>{selected?.door?.cod}</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {!selected.caught && (
            <>
              <Card className={classes.optionCard}>
                <CardContent>
                  <Typography variant="h5">Alterar email</Typography>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Novo email"
                        formControlProps={{
                          fullWidth: true,
                          style: { margin: 0 },
                        }}
                        inputProps={{
                          type: "email",
                          name: "newEmail",
                          onChange: (e) =>
                            setNewEmail(e.target.value.toLocaleLowerCase()),
                          value: newEmail,
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Confirmar novo email"
                        formControlProps={{
                          fullWidth: true,
                          style: { margin: 0 },
                        }}
                        inputProps={{
                          type: "email",
                          name: "newEmail",
                          onChange: (e) =>
                            setConfNewEmail(e.target.value.toLocaleLowerCase()),
                          value: confNewEmail,
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.center}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={updateEmail}
                    disabled={loading}
                  >
                    Alterar
                  </Button>
                </CardActions>
              </Card>
              <Card className={classes.optionCard}>
                <CardContent>
                  <Typography variant="h5">Alterar celular</Typography>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="DDD + Novo celular"
                        formControlProps={{
                          fullWidth: true,
                          style: { margin: 0 },
                        }}
                        inputProps={{
                          type: "tel",
                          name: "newCellphone",
                          onChange: procCellNumber,
                          value: newCellphone,
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocalPhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Confirmar DDD + novo celular"
                        formControlProps={{
                          fullWidth: true,
                          style: { margin: 0 },
                        }}
                        inputProps={{
                          type: "tel",
                          name: "confNewCellphone",
                          onChange: procCellNumber,
                          value: confNewCellphone,
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocalPhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.center}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={updateCellphone}
                    disabled={loading}
                  >
                    Alterar
                  </Button>
                </CardActions>
              </Card>
            </>
          )}
        </>
      ) : (
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          {deliveries.length === 0 && (
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography variant="body1" align="center">
                    Sem histórico de entregas
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {deliveries.map((item, index) => (
            <Grid
              item
              xs={12}
              className={
                item?.caught ? classes.cardGridGreen : classes.cardGridBlue
              }
              key={index}
            >
              <Card className={classes.root}>
                <CardContent>
                  <Typography variant="body2" className={classes.emailText}>
                    Info: <b>{item?.info ? item?.info : "---"}</b>
                  </Typography>
                  <div className={classes.dataContainer}>
                    <Typography variant="body1">
                      Entregue:{" "}
                      <b>{moment(item.createdAt).format("DD/MM/YY HH:mm")}</b>
                    </Typography>
                    <Typography variant="body1">
                      Armário:{" "}
                      <b>
                        {item?.board.address
                          ? item?.board?.address.split("-")[0]
                          : "-"}
                      </b>
                    </Typography>
                    <Typography variant="body1">
                      Porta: <b>{item?.door?.cod}</b>
                    </Typography>
                    <Typography variant="body1" className={classes.caughtText}>
                      {item?.caught ? "Retirada" : "Aguardando retirada"}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setSelected(item);
                    }}
                  >
                    Detalhes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

export default ListDeliveries;
