/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import mainTheme from "./theme";
import queryString from "querystring";
import * as serviceWorker from "./serviceWorker";

import { AuthContext, defaultContextValue } from "./context/AuthContext";

// core components
import Admin from "layouts/Admin.js";

import Login from "views/Login/Login";
import Register from "views/Register/Register";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import ResetPassword from "views/ResetPassword/ResetPassword";
import ResetDoorPwd from "views/ResetDoorPwd/ResetDoorPwd";
import WithdrawDelivery from "views/WithdrawDelivery/WithdrawDelivery";
import Support from "views/Support/Support";
//import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "assets/scss/mainScss.scss?v=1.8.0";

import isAuthenticated from "functions/isAuthenticated";
import SalesmanLayout from "layouts/Salesman";

const hist = createBrowserHistory();

// eslint-disable-next-line react/prop-types
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated() ? children : <Redirect to="/" />)}
    />
  );
}

const SwitchRoute = () => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [parsed, setParsed] = useState(queryString.parse(location.search));

  useEffect(() => {
    setParsed(queryString.parse(location.search));
  }, [location]);

  if (parsed["?activation"]) {
    return <Route path="/" component={Login} />;
  }
  if (isAuthenticated()) {
    if (authContext.value().salesman) {
      return <Redirect to="/salesman/dashboard" />;
    }
    return <Redirect to="/admin/dashboard" />;
  }
  return <Route path="/" component={Login} />;
};

ReactDOM.render(
  <AuthContext.Provider value={defaultContextValue}>
    <ThemeProvider theme={mainTheme}>
      <Router history={hist}>
        <Switch>
          <PrivateRoute path="/admin/*">
            <Route component={Admin} />
          </PrivateRoute>
          <PrivateRoute path="/salesman/*">
            <Route component={SalesmanLayout} />
          </PrivateRoute>
          <Route
            exact
            component={ResetDoorPwd}
            path="/reset/door/forgot/pwd/:token"
          />
          {/* <Route path="/rtl" component={RTL} /> */}
          <Route path="/register" component={Register} />
          <Route path="/forgot/password" component={ForgotPassword} />
          <Route path="/support" component={Support} />
          <Route path="/resetPassword/:token" component={ResetPassword} />
          <Route
            path="/withdraw/delivery/:token"
            component={WithdrawDelivery}
          />
          <SwitchRoute />
        </Switch>
      </Router>
    </ThemeProvider>
  </AuthContext.Provider>,
  document.getElementById("root")
);

serviceWorker.register();
