import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import Snackbar from "components/Snackbar/Snackbar.js";
import axios from "config/axios";
import useStyles from "./styles";

function MakeDelivery() {
  const [loading, setLoading] = useState(false),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState(""),
    [cords, setCords] = useState();

  const classes = useStyles();

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.watchPosition(
        function (position) {
          setCords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accu: position.coords.accuracy,
          });
        },
        (error) => {
          console.log("Erro ao ler GPS: " + error);
        },
        { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
      );
    } else {
      setAlert("Geolocalização não suportada pelo navegador");
    }
  }, []);

  const openDoors = async () => {
    setLoading((oldState) => !oldState);
    axios
      .post("/salesman/promo/open/board", {
        lat: cords.lat,
        long: cords.long,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlertSuccess("Abertura das portas em andamento");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        const statusError = error?.response?.data?.status;
        switch (statusError) {
          //Caso 1001 do middleware de venvedor
          case 1001:
            setAlert("Você não é um vendedor");
            break;
          case 1002:
            setAlert("Você não tem permissão para promoção");
            break;
          case 408:
            setAlert("Tempo de abertura esgotado");
            break;
          case 406:
            setAlert("Você está longe do armário");
            break;
          default:
            setAlert("Problema ao abrir");
            break;
        }
      });
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color={"danger"}
        icon={ErrorIcon}
        message={alert ? alert : ""}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess ? alertSuccess : ""}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Abrir armário</Typography>
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
              ></Grid>
            </CardContent>
            <CardActions
              className={classes.center}
              style={{ marginTop: "1rem" }}
            >
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={openDoors}
                  disabled={loading}
                  size="large"
                >
                  Abrir armário
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default MakeDelivery;
