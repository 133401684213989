import React, { useState, useEffect } from "react";

// @material-ui/core
import {
  InputAdornment,
  Button,
  Checkbox,
  FormControlLabel,
  CardActions,
  Card,
  CardContent,
  Typography,
  Paper,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Select from "react-select";
//import { makeStyles } from "@material-ui/core/styles";

import axios from "config/axios";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import EmailIcon from "@material-ui/icons/Email";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import SupportIcon from "@material-ui/icons/Help";

// custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InstallPWAModel from "components/InstallPWAModel/InstallPWAModel.js";
import RentPriceModal from "components/RentPriceModal";

//import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import isEmail from "functions/isEmail";

//const useStyles = makeStyles(styles);
import useStyles from "./styles";

const selectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#8a6eb2" : isFocused && "#05b5a9",
      color: isSelected ? "white" : isFocused && "white",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
      },
    };
  },
};

export default function Dashboard() {
  //const classes = useStyles();
  const [alert, setAlert] = useState({
      alert: false,
      msg: "",
      color: "danger",
    }),
    [doorPassword, setDoorPassword] = useState(""),
    [endDoor, setEndDoor] = useState(false),
    [selectedOpenDoor, setSelectedOpenDoor] = useState(),
    [selectedAccessDoor, setSelectedOAccessDoor] = useState(),
    [rentCod, setRentCod] = useState(""),
    [rentPwd, setRentPwd] = useState(""),
    [accessEmail, setAccessEmail] = useState(""),
    [accessCell, setAccessCell] = useState(""),
    [rentLoading, setRentLoading] = useState(false),
    [openLoading, setOpenLoading] = useState(false),
    [accessLoading, setAccessLoading] = useState(false),
    [allDoorsCod, setAllDoorsCod] = useState([]),
    [suggestions, setSuggestions] = useState(),
    [disableFields, setDisableFields] = useState(false),
    [cords, setCords] = useState({ lat: 0, long: 0, accu: 0 }),
    [rentPrice, setRentPrice] = useState(),
    [userDoorsCod, setUserDoorsCod] = useState([]);

  const classes = useStyles();

  const getDoors = async () => {
    axios
      .get("/user/get/doors")
      .then(({ data }) => {
        let result = [];
        data.forEach((e) => {
          result.push({ value: e._id, label: e.cod });
        });
        setAllDoorsCod(result);
      })
      .catch((error) => {
        let status = error?.response?.status;
        switch (status) {
          case 401:
            setAlert({ alert: true, msg: "Não autorizado", color: "danger" });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao carregar",
              color: "danger",
            });
            break;
        }
      });
    axios
      .get("/user/get/own/doors")
      .then(({ data }) => {
        let result = [];
        data.forEach((e) => {
          result.push({ value: e._id, label: e.cod });
        });
        setUserDoorsCod(result);
      })
      .catch((error) => {
        let status = error?.response?.status;
        switch (status) {
          case 401:
            setAlert({ alert: true, msg: "Não autorizado", color: "danger" });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao carregar",
              color: "danger",
            });
            break;
        }
        console.log(error);
      });
  };

  /* UseEffect que carrega as portas */
  useEffect(() => {
    getDoors();
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.watchPosition(
        function (position) {
          setCords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accu: position.coords.accuracy,
          });
        },
        (error) => {
          console.log("Erro ao ler GPS: " + error);
        },
        { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
      );
    } else {
      setAlert({
        msg: "Geolocalização não suportada pelo navegador",
        alert: true,
      });
      setDisableFields(true);
    }
  }, []);

  const openUserDoor = async () => {
    if (!selectedOpenDoor) {
      return setAlert({
        alert: true,
        msg: "Selecione a porta para continuar",
        color: "warning",
      });
    }
    if (!doorPassword) {
      return setAlert({
        alert: true,
        msg: "Entre com a senha da porta para continuar",
        color: "warning",
      });
    }
    setOpenLoading(true);
    axios
      .post("/user/open/door", {
        doorId: selectedOpenDoor.value,
        doorPwd: doorPassword,
        end: endDoor,
        lat: cords?.lat,
        long: cords?.long,
      })
      .then(async ({ data }) => {
        setOpenLoading(false);
        await getDoors();
        if (data.delivery) {
          let index = allDoorsCod.indexOf(selectedOpenDoor);
          allDoorsCod.splice(index, 1);
          setAllDoorsCod(allDoorsCod);
          setSelectedOpenDoor(null);
          return setAlert({
            alert: true,
            msg: "Encomenda retirada com sucesso!",
            color: "success",
          });
        }
        if (endDoor) {
          let index = allDoorsCod.indexOf(selectedOpenDoor);
          allDoorsCod.splice(index, 1);
          setAllDoorsCod(allDoorsCod);
          setSelectedOpenDoor(null);
          return setAlert({
            alert: true,
            msg: "Porta aberta! SEU ALUGUEL FOI ENCERRADO",
            color: "success",
          });
        }

        setAlert({
          alert: true,
          msg: "Porta aberta com sucesso",
          color: "success",
        });
      })
      .catch((error) => {
        setOpenLoading(false);
        getDoors();
        console.log(error);
        switch (error?.response?.status) {
          case 400:
            switch (error?.response?.data?.status) {
              case 400:
                return setAlert({
                  alert: true,
                  msg: "Porta não encontrada",
                  color: "danger",
                });
              case 408:
                return setAlert({
                  alert: true,
                  msg: "Tempo de abertura esgotado",
                  color: "danger",
                });
              default:
                return setAlert({
                  alert: true,
                  msg: "Porta não encontrada",
                  color: "danger",
                });
            }
          case 402:
            return setAlert({
              alert: true,
              msg: "Problema ao realizar pagamento",
              color: "danger",
            });
          case 418:
            return setAlert({
              alert: true,
              msg: "Você está longe da porta",
              color: "danger",
            });
          case 404:
            return setAlert({
              alert: true,
              msg: "Usuário não encontrado",
              color: "danger",
            });
          case 401:
            return setAlert({
              alert: true,
              msg: "Senha incorreta",
              color: "danger",
            });
          case 403:
            return setAlert({
              alert: true,
              msg: "Porta não alugada",
              color: "danger",
            });
          case 425:
            return setAlert({
              alert: true,
              msg: "Sem forma de pagamento",
              color: "danger",
            });
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao abrir porta",
              color: "danger",
            });
        }
      });
  };

  const checkDoor = async () => {
    if (!rentCod || !rentPwd) {
      return setAlert({
        alert: true,
        msg: "Preencha os campos",
        color: "warning",
      });
    }
    setRentLoading(true);
    axios
      .post("/user/check/door", {
        doorCod: rentCod.toLowerCase(),
      })
      .then(({ data }) => {
        setRentLoading(false);
        setRentPrice(data.price);
      })
      .catch((error) => {
        setRentLoading(false);
        getDoors();
        console.log(error);
        switch (error?.response?.data?.status) {
          case 1:
            return setAlert({
              alert: true,
              msg: "Armário não ativado",
              color: "danger",
            });
          case 400:
            return setAlert({
              alert: true,
              msg: "Porta não encontrada",
              color: "danger",
            });
          case 408:
            return setAlert({
              alert: true,
              msg: "Tempo de abertura esgotado",
              color: "danger",
            });
          case 401:
            setSuggestions(error.response.data.doors);
            return setAlert({
              alert: true,
              msg: "Porta ocupada.",
              color: "danger",
            });
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao verificar porta",
              color: "danger",
            });
        }
      });
  };

  const giveAccess = async () => {
    if (!isEmail(accessEmail)) {
      return setAlert({
        alert: true,
        msg: "Email inválido",
        color: "warning",
      });
    }
    if (accessCell.length !== 11) {
      return setAlert({
        alert: true,
        msg: "Celular inválido",
        color: "warning",
      });
    }
    if (!selectedAccessDoor) {
      return setAlert({
        alert: true,
        msg: "Selecione uma porta para continuar",
        color: "warning",
      });
    }
    setAccessLoading(true);
    axios
      .post("/user/give/access", {
        doorId: selectedAccessDoor.value,
        cellphone: "+55" + accessCell,
        email: accessEmail,
      })
      .then(() => {
        setAccessLoading(false);
        getDoors();
        setAlert({
          alert: true,
          msg: "Adicionado com sucesso",
          color: "success",
        });
      })
      .catch((error) => {
        setAccessLoading(false);
        getDoors();
        console.log(error);
        switch (error?.response?.status) {
          case 400:
            return setAlert({
              alert: true,
              msg: "Porta selecionada não encontrada",
              color: "danger",
            });
          case 401:
            return setAlert({
              alert: true,
              msg: "Porta não alugada",
              color: "danger",
            });
          case 402:
            return setAlert({
              alert: true,
              msg: "Problema ao realizar pagamento",
              color: "danger",
            });
          case 403:
            return setAlert({
              alert: true,
              msg: "Permissão negada",
              color: "danger",
            });
          case 409:
            return setAlert({
              alert: true,
              msg: "Convidado não encontrado",
              color: "danger",
            });
          case 406:
            return setAlert({
              alert: true,
              msg: "Conta do convidado não ativada",
              color: "danger",
            });
          case 408:
            return setAlert({
              alert: true,
              msg: "Não é possivel se dar acesso",
              color: "danger",
            });
          case 425:
            return setAlert({
              alert: true,
              msg: "Sem forma de pagamento",
              color: "danger",
            });
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao dar acesso",
              color: "danger",
            });
        }
      });
  };

  const onAccessDropdownChange = async (e) => {
    setSelectedOAccessDoor(e);
  };

  const onChangeOpenDropdown = async (e) => {
    setSelectedOpenDoor(e);
  };

  const procCellNumber = async (e) => {
    let value = e.target.value;
    value = value.replace(/\D/, "");
    if (value.length > 11) {
      return;
    }
    setAccessCell(value);
  };

  const procCod = async (e) => {
    let value = e.target.value;
    if (value.length > 8) {
      return;
    }
    setRentCod(value);
  };

  if (disableFields) {
    return (
      <Paper>
        <Typography variant="h4" align="center" style={{ padding: "1.2rem" }}>
          Localização necessária para usar o app
        </Typography>
      </Paper>
    );
  }

  const forgotPwd = async () => {
    if (!selectedOpenDoor) {
      return setAlert({
        alert: true,
        msg: "Selecione uma porta no seletor",
        color: "warning",
      });
    }
    axios
      .post("/user/forgot/door/pwd", {
        doorId: selectedOpenDoor.value,
      })
      .then(() => {
        setAlert({
          alert: true,
          msg: "Pedido de troca enviado para o seu email",
          color: "success",
        });
      })
      .catch((error) => {
        switch (error?.response?.data?.status) {
          case 1:
            setAlert({
              alert: true,
              msg: "Porta não encontrada",
              color: "danger",
            });
            break;
          case 2:
            setAlert({
              alert: true,
              msg: "A porta não é sua",
              color: "danger",
            });
            break;
          case 3:
            setAlert({
              alert: true,
              msg: "Não é permitido alterar a senha de uma porta com encomenda",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao pedir troca de senha",
              color: "danger",
            });
            break;
        }
      });
  };

  return (
    <div>
      <InstallPWAModel />

      <Snackbar
        place="tc"
        color={alert.color}
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() =>
          setAlert({ alert: false, msg: "", color: alert.color })
        }
        close
      />
      <RentPriceModal
        handleClose={() => {
          setRentPrice(null);
        }}
        price={rentPrice}
        rentCod={rentCod}
        rentPwd={rentPwd}
        setAlert={setAlert}
        getDoors={getDoors}
      />
      <GridContainer>
        {cords?.accu > 50 && (
          <GridItem xs={12}>
            <Paper>
              <Typography
                variant="h5"
                align="center"
                style={{ padding: "1.2rem" }}
              >
                Sinal de GPS fraco
              </Typography>
            </Paper>
          </GridItem>
        )}
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <Card>
            <CardContent>
              <Typography variant="h5">Alugar</Typography>
              {suggestions && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ fontSize: "1.2rem", flexDirection: "column" }}
                    >
                      Portas disponíveis:
                    </Typography>
                  </Grid>
                  {suggestions.map((item, index) => (
                    <Grid item key={index}>
                      <Button
                        key={index}
                        style={{ fontSize: "1.2rem", textTransform: "none" }}
                        onClick={() => setRentCod(item.cod)}
                      >
                        <b>{item.cod}</b>
                      </Button>
                    </Grid>
                  ))}
                  {suggestions.length === 0 && (
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="center"
                        style={{ flexDirection: "column" }}
                      >
                        <b>Não há portas disponíveis</b>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Código da porta"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "cod",
                      onChange: procCod,
                      value: rentCod,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <MeetingRoomIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Senha para a porta"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      name: "cod",
                      onChange: (e) => setRentPwd(e.target.value),
                      value: rentPwd,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <VpnKeyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
            <CardActions
              className={classes.center}
              style={{ marginTop: "1rem" }}
            >
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={checkDoor}
                  disabled={rentLoading}
                  size="large"
                >
                  Ver preço
                </Button>
                {rentLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </CardActions>
          </Card>
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <Paper style={{ padding: "1.5rem" }}>
            <Typography variant="h5">Abrir</Typography>
            <div style={{ marginTop: "1rem" }}>
              <Select
                options={allDoorsCod}
                onChange={onChangeOpenDropdown}
                //value={defaultOption}
                placeholder="Selecione uma porta"
                className={classes.selector}
                styles={selectStyles}
                value={selectedOpenDoor}
              />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <CustomInput
                    labelText="Senha"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      name: "passowrd",
                      onChange: (e) => setDoorPassword(e.target.value),
                      value: doorPassword,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <VpnKeyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    startIcon={<SupportIcon />}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={forgotPwd}
                  >
                    Esqueci a senha
                  </Button>
                </Grid>
              </Grid>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={endDoor}
                      onChange={() => setEndDoor(!endDoor)}
                      name="checkedB"
                      color="secondary"
                    />
                  }
                  label="Encerrar aluguel?"
                  style={{ color: "black" }}
                />
              </div>
            </div>

            <div className={classes.center} style={{ marginTop: "1rem" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={openUserDoor}
                  disabled={openLoading}
                  size="large"
                >
                  Abrir
                </Button>
                {openLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </Paper>
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <Paper style={{ padding: "1rem" }}>
            <Typography variant="h5">Dar acesso</Typography>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email do convidado"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "cod",
                    onChange: (e) => setAccessEmail(e.target.value),
                    value: accessEmail,
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Telefone da pessoa DDD + número"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "cell",
                    name: "cod",
                    onChange: procCellNumber,
                    value: accessCell,
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} style={{ zIndex: 1 }}>
                <Select
                  options={userDoorsCod}
                  onChange={onAccessDropdownChange}
                  //value={defaultOption}
                  placeholder="Selecione uma porta"
                  className={classes.selector}
                  styles={selectStyles}
                />
              </GridItem>
            </GridContainer>
            <div className={classes.center} style={{ marginTop: "1rem" }}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={giveAccess}
                  disabled={accessLoading}
                  size="large"
                >
                  Dar acesso
                </Button>
                {accessLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  );
}
